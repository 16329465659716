import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  return (
    <div className={css.sectionReviews}>
      <div className={css.SectionHeader}>
        <h2 className={css.sectionTitle}>
          <FormattedMessage id="ListingPage.reviewsTitle" values={{ count: reviews.length }} />
        </h2>
        <span className={css.viewAllBtn}>
          <FormattedMessage id="ListingPage.viewAll" />
        </span>
      </div>

      {fetchReviewsError ? reviewsError : null}
      {reviews && reviews.length > 0 ? (
        <Reviews reviews={reviews} />
      ) : (
        <p>
          <FormattedMessage id="ListingPage.noReviews" />
        </p>
      )}
    </div>
  );
};

export default SectionReviews;
