import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCollection, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';
import { availabilities, colors, listingStores } from '../../config/marketplace-custom-config';
import { getColorCode } from '../../util/dataExtractor';

const getLabel = (category, key) => {
  const label = category.find(c => c.key === key);
  return label ? label.label : key;
};

const SectionDetailsMaybe = props => {
  const { publicData, customConfig,currentStock } = props;
  const { listingStore, availability, condition, color } = publicData || {};
  const { listing, filters } = customConfig || {};

  if (!publicData || !customConfig || !listing?.enumFieldDetails) {
    return null;
  }

  const pickExtendedData = filterConfig => (rows, key) => {
    const publicDataValue = publicData[key];
    if (publicDataValue) {
      const filterIfItExists = filterConfig.find(f => f.id === key);
      const filterOptions = filterIfItExists?.config?.options || [];
      const value = filterOptions.find(o => o.key === publicDataValue)?.label || publicDataValue;
      const label = filterIfItExists?.label || `${key.charAt(0).toUpperCase()}${key.slice(1)}`;

      return rows.concat({ key, value, label });
    }
    return rows;
  };

  const existingExtendedData = listing?.enumFieldDetails.reduce(pickExtendedData(filters), []);

  return existingExtendedData ? (
    <>
      <div className={css.sectionDetails}>
        <h2 className={css.detailsTitle}>
          <FormattedMessage id="ListingPage.detailsTitle" />
        </h2>
        <div className={css.detailsRow}>
          <span className={css.detailLabel}>
            <FormattedMessage id="ListingPage.storeText" />
          </span>
          <span>{getLabel(listingStores, listingStore)}</span>
        </div>
        <div className={css.detailsRow}>
          <span className={css.detailLabel}>
            <FormattedMessage id="ListingPage.availabilityText" />
          </span>
          <span>{currentStock === 0 ? "Sold": getLabel(availabilities, availability)}</span>
        </div>
        <div className={css.detailsRow}>
          <span className={css.detailLabel}>
            <FormattedMessage id="ListingPage.conditionText" />
          </span>
          <span className={css.textFormation}>
            {condition} <IconCollection icon="storeIcon" />
          </span>
        </div>
        <div className={css.detailsRow}>
          <span className={css.detailLabel}>
            <FormattedMessage id="ListingPage.colorText" />
          </span>
          <span className={css.colorDetails}>
            {' '}
            <span className={css.bgColor} style={{ backgroundColor: getColorCode(color) }}></span>
            <span>{getLabel(colors, color)}</span>
          </span>
        </div>
      </div>
      <div className={css.sectionDetails}>
        <h2 className={css.detailsTitle}>
          <FormattedMessage id="ListingPage.categoryTitle" />
        </h2>
        <ul className={css.categoryWrapper}>
          {existingExtendedData.map(detail => (
            <li key={detail.key} className={css.categories}>
              <span>{detail.value}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  ) : null;
};

export default SectionDetailsMaybe;
